export enum FreigabeStatus {
  PENDING = 0,
  PASSED = 1,
  DECLINED = 2,
}

export namespace FreigabeStatus {

  export function iconForStatus(status: FreigabeStatus): string {
    switch (status) {
      case FreigabeStatus.DECLINED:
        return 'cancel';
      case FreigabeStatus.PENDING:
        return 'watch_later';
      case FreigabeStatus.PASSED:
        return 'check_circle';
    }
  }

}