export interface AnforderungStatus {
  code: AnforderungStatusCode;
  message: string;
}

export enum AnforderungStatusCode {
  NEW = 0,
  PROCESSING = 1,
  TRANSFERRED = 2,
  ERROR = 3,
  DECLINED = 4
}
