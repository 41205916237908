import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';


import { Anforderung } from './anforderung';
import { AnforderungFreigabe } from './anforderung-freigabe';
import { AnforderungStatusCode } from './anforderung-status';
import { AnforderungenFilter } from '..';
import { PageResponse } from '@mp/shared/data-access';
import { QueryParams } from '@core/shared/util';

@Injectable()
export class AnforderungenService {

  private readonly baseUrl = '/api/medicalshop/auftraege';
  private readonly apiPath = 'anforderungen';

  constructor(private readonly http: HttpClient) { }

  getAll(filter?: AnforderungenFilter): Observable<Array<Anforderung>> {
    const params = this.getParams(filter);
    return this.http
      .get<PageResponse<Anforderung>>(`${this.baseUrl}/${this.apiPath}`, { params })
      .pipe(pluck('data'));
  }

  getPageResponse(filter?: AnforderungenFilter): Observable<PageResponse<Anforderung>> {
    const params = this.getParams(filter);
    return this.http.get<PageResponse<Anforderung>>(`${this.baseUrl}/${this.apiPath}`, { params });
  }

  getCount(from?: Date): Observable<{ [status: string]: number}> {
    const params = QueryParams.build<Anforderung>();
    if (from) {
      params.filter('createdAt', '>=' , from.toISOString());
    }

    return this.http.get<{ [status: string]: number }>(
      '/api/kennzahlen/anforderungen',
      { params: params.toHttpParams() }
    );
  }

  getTotalPrice(from?: Date, anforderungStatus?: Array<AnforderungStatusCode>): Observable<number> {
    const params = QueryParams.build<Anforderung>();
    if (from) {
      params.filter('createdAt', '>=' , from.toISOString());
    }

    return this.http.get<number>(
      '/api/kennzahlen/auftragswert',
      { params: params.toHttpParams() }
    );
  }

  private getParams(filter?: AnforderungenFilter): HttpParams {
    const queryParams = QueryParams.build<Anforderung>();
    if (filter?.page) {
      queryParams
        .page(filter.page)
        .pageSize(filter.pageSize)
        .toHttpParams();
    }

    if (filter?.filterFrom && filter?.filterTo) {
      queryParams.filter('createdAt', '>=' , filter.filterFrom);
      queryParams.filter('createdAt', '<=', filter.filterTo);
    }

    let params = queryParams.toHttpParams();
    if(filter?.searchTerm) {
      params = params.append('searchTerm', filter.searchTerm);
    }

    if(filter?.status) {
      for (const s of filter.status) {
        params = params.append('status', s.toString());
      }
    }

    if(filter?.freigabeStatus) {
      for (const s of filter.freigabeStatus) {
        params = params.append('freigabeStatus', s.toString());
      }
    }

    return params;
  }

  freigabe(freigabe: AnforderungFreigabe): Observable<Anforderung> {
    return this.http.post<Anforderung>(`${this.baseUrl}/${this.apiPath}/freigabe`, freigabe);
  }

}
